<template>
	<div data-component="quiz">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="formLoaded"
		>
			<input-field
				name="Question number e.g. 2d"
				type="text"
				rules="required"
				v-model="getTitle"
			/>
			<wysiwyg-field
				name="Question"
				type="text"
				rules="required"
				:hasListControl="false"
				:hasLinkControl="false"
				v-model="getQuestion"
			/>
			<wysiwyg-field
				name="Hint"
				type="text"
				:hasListControl="false"
				:hasLinkControl="false"
				v-model="getHint"
			/>
			<diagrams
				heading="Question diagrams"
				context="question"
			/>
			<diagrams
				heading="Answer diagrams"
				context="answer"
			/>
			<checkbox-field
				v-if="getShowMustChooseAllCorrectAnswers"
				name="Require all correct answers to be given"
				v-model="getMustChooseAllCorrectAnswers"
			/>
			<wysiwyg-field
				name="Answer explanation"
				type="text"
				:hasListControl="false"
				v-model="getExplanation"
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
			/>
			<diagram-modal
				v-if="getDiagramInModal"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField        from '@/components/forms/InputField';
	import Diagrams          from '@/components/activities/edit/activityTypes/quiz/Diagrams';
	import DiagramModal      from '@/components/activities/edit/activityTypes/quiz/DiagramModal';
	import CheckboxField     from '@/components/forms/CheckboxField';
	import Actions           from '@/components/ui/Actions';
	import activityDefaults  from '@/mixins/activityDefaults';

	export default {
		components: {
			InputField,
			Diagrams,
			DiagramModal,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			CheckboxField,
			Actions
		},
		mixins: [activityDefaults],
		data: () => ({}),
		computed: {
			getTitle: {
				get () {
					return this.$store.getters['quiz/getTitle'];
				},
				set (value) {
					return this.$store.commit('quiz/setTitle', {
						title: value
					});
				}
			},
			getQuestion: {
				get () {
					return this.$store.getters['quiz/getQuestion'];
				},
				set (value) {
					return this.$store.commit('quiz/setQuestion', {
						question: value
					});
				}
			},
			getHint: {
				get () {
					return this.$store.getters['quiz/getHint'];
				},
				set (value) {
					return this.$store.commit('quiz/setHint', {
						hint: value
					});
				}
			},
			getMustChooseAllCorrectAnswers: {
				get () {
					return this.$store.getters['quiz/getMustChooseAllCorrectAnswers'];
				},
				set (value) {
					return this.$store.commit('quiz/setMustChooseAllCorrectAnswers', {
						mustChooseAllCorrectAnswers: value
					});
				}
			},
			getExplanation: {
				get () {
					return this.$store.getters['quiz/getExplanation'];
				},
				set (value) {
					return this.$store.commit('quiz/setExplanation', {
						explanation: value
					});
				}
			},
			getQuiz () {
				return this.$store.getters['quiz/getQuiz'];
			},
			getDiagrams () {
				return this.$store.getters['quiz/getAllDiagrams'];
			},
			getDiagramInModal () {
				return this.$store.getters['quiz/getDiagramInModal'];
			},
			getNumberOfCorrectAnswers () {
				return this.$store.getters['quiz/getNumberOfCorrectAnswers'];
			},
			getHasMultipleCorrectAnswers () {
				return this.$store.getters['quiz/getHasMultipleCorrectAnswers'];
			},
			getShowMustChooseAllCorrectAnswers () {
				return this.getHasMultipleCorrectAnswers;
			}
		},
		watch: {
			getNumberOfCorrectAnswers: {
				handler (value) {
					if (value !== false && value < 2) {
						this.$store.commit('quiz/setMustChooseAllCorrectAnswers', {
							mustChooseAllCorrectAnswers: false
						});
					}
				}
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

::v-deep [data-component=input-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  input {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);

  }
}

::v-deep [data-component=wysiwyg-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  .quill-editor {
    background-color: $c-white-l;
    font-family: "DM Sans", sans-serif !important;

    p {
      font-family: "DM Sans", sans-serif !important;
    }
  }

  .ql-container.ql-snow {
    border: 1px solid $c-gray-l;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid $c-gray-l;
  }
}

::v-deep [data-component=range-field] > span {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  input {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);
  }

  .vue-slider-process {
    background-color: $c-medium-navy !important;
  }

  .vue-slider:hover .vue-slider-process {
    background-color: $c-darkest-navy !important;
  }

  .vue-slider-dot-handle {
    border: 2px solid $c-medium-navy !important;
  }

  .vue-slider-mark-step-active {
    box-shadow: 0 0 0 2px $c-medium-navy !important;
  }

  .vue-slider-dot-handle-focus {
    box-shadow: 0 0 0 5px rgb(186, 193, 202, 0.2);
  }
}

::v-deep [data-component=select-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  .multiselect__tags {
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);
  }
}

	[data-component='quiz'] {}

</style>
